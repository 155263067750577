import React, { useCallback } from 'react';
import { graphql } from 'gatsby';
import { grey6 } from '@lib/components/bedrock/SwColors';
import SwWebsiteContainer from '@lib/components/layout/SwWebsiteContainer';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import { PlatformSection, PlatformSectionsEnum } from '@app/platform/reducers/platformReducer';
import FlashBanner from '../app/common/components/FlashBanner';
import Seo from '../app/common/components/Seo';
import PlatformProvider from '../app/platform/PlatformProvider';
import PlatformSections from '../app/platform/sections/PlatformSections';
import SwHeader from '../app/platform/sections/SwHeader';
import SwLayout from '../components/v1/SwLayout';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';
import { CDNClient } from '../lib/helpers/image';

const PlatformPage = ({ data }) => {
    const getTabsInformation = useCallback(
        (index, key) => data.page.sections[index]?.children?.[0].children?.[0].children[key],
        [data.page]
    );

    const PLATFORM_TABS: Array<PlatformSection> = [
        {
            number: 1,
            title: getTabsInformation(0, 'title'),
            description: getTabsInformation(0, 'description'),
            key: PlatformSectionsEnum.Understand,
            areaNumber: 1
        },
        {
            number: 2,
            title: getTabsInformation(1, 'title'),
            description: getTabsInformation(1, 'description'),
            key: PlatformSectionsEnum.Build,
            areaNumber: 8
        },
        {
            number: 3,
            title: getTabsInformation(2, 'title'),
            description: getTabsInformation(2, 'description'),
            key: PlatformSectionsEnum.Feedback,
            areaNumber: 5
        },
        {
            number: 4,
            title: getTabsInformation(3, 'title'),
            description: getTabsInformation(3, 'description'),
            key: PlatformSectionsEnum.Precision,
            areaNumber: 4
        },
        {
            number: 5,
            title: getTabsInformation(4, 'title'),
            description: getTabsInformation(4, 'description'),
            key: PlatformSectionsEnum.Involve,
            areaNumber: 6
        },
        {
            number: 6,
            title: getTabsInformation(5, 'title'),
            description: getTabsInformation(5, 'description'),
            key: PlatformSectionsEnum.Collaborate,
            areaNumber: 7
        },
        {
            number: 7,
            title: getTabsInformation(6, 'title'),
            description: getTabsInformation(6, 'description'),
            key: PlatformSectionsEnum.Target,
            areaNumber: 9
        },
        {
            number: 8,
            title: getTabsInformation(7, 'title'),
            description: getTabsInformation(7, 'description'),
            key: PlatformSectionsEnum.Contributions,
            areaNumber: 3
        },
        {
            number: 9,
            title: getTabsInformation(8, 'title'),
            description: getTabsInformation(8, 'description'),
            key: PlatformSectionsEnum.Report,
            areaNumber: 2
        }
    ];

    const computedSections = data.page.sections.map((section, index) => ({
        ...section,
        children: [data.sectionsCards.nodes.find(({ number }) => number === index + 1), ...section.children]
    }));

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.platform.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <PlatformProvider>
                <SwHeader
                    banner={data.flashBanners.edges[0].node}
                    subtitle={data.page.subtitle}
                    tabs={PLATFORM_TABS}
                    title={data.page.title}
                />
                <SwWebsiteContainer>
                    <SwLayout background={grey6}>
                        <PlatformSections sections={computedSections} tabs={PLATFORM_TABS} />
                    </SwLayout>
                    <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                </SwWebsiteContainer>
            </PlatformProvider>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        page: { seo },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    >
        <link as={'image'} href={CDNClient.buildURL('/logos/Sweepey.gif')} rel={'preload'} />
    </Seo>
);

export const pageQuery = graphql`
    query getPlatformPageInformation($id: String, $locale: GraphCMS_Locale = en) {
        latestArticles: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        enPage: graphCmsPage(slug: { eq: "platform" }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        sectionsCards: allGraphCmsSectionCard(
            filter: {
                locale: { eq: $locale }
                remoteId: {
                    in: [
                        "clgc1a3wi9ef70aw2bfksa328"
                        "clgc1ch1g9elk0aw23cdoejs5"
                        "clgc1edvd9ejr0aw4c38rrjnn"
                        "clgc1i6vm9h5o0bvzws38evzg"
                        "clgc1lvov9f2p0aw2nohxukol"
                        "clgc1q8nx9ez60aw4g93hk47n"
                        "clgc1t7gj9hld0bvziuqptkje"
                        "clgc1x6kk9fhz0aw2qs2ohxxo"
                        "clgc204a09fzo0aw2c1jpeb5w"
                    ]
                }
            }
        ) {
            nodes {
                ...Essentials_GraphCMS_SectionCard
            }
        }
        page: graphCmsPage(id: { eq: $id }) {
            id
            remoteId
            title
            subtitle
            slug
            seo {
                description
                noIndex
                title
                image {
                    url
                }
            }
            sections {
                ... on GraphCMS_Section {
                    id
                    __typename
                    target
                    children {
                        ...Essentials_GraphCMS_Divider
                        ...Essentials_GraphCMS_MediaCard
                        ... on GraphCMS_Grid {
                            ...Essentials_GraphCMS_Grid
                            children {
                                ... on GraphCMS_GridItem {
                                    ...Essentials_GraphCMS_GridItem
                                    children {
                                        ...Essentials_GraphCMS_CustomersCarousel
                                        ...Essentials_GraphCMS_CallToAction
                                        ...Essentials_GraphCMS_FeatureCard
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default PlatformPage;
