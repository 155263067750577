import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import SwCard from '../../../components/v1/SwCard';
import SwContainer from '../../../components/v1/SwContainer';
import { black, grey3, grey7 } from '../../../lib/components/bedrock/SwColors';
import SwSpacer from '../../../lib/components/bedrock/layout/SwSpacer';
import SwTypography from '../../../lib/components/bedrock/typography/SwTypography';
import { BasePlatformProps } from './PlatformSections';

const StyledImage = styled('img')`
    width: 100%;
    height: auto;
`;

const StyledSwCard = styled(SwCard)`
    display: flex;
    position: relative;
`;

const GridContainer = styled('div')`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
    gap: ${({ theme }) => theme.spacing(2)};
`;
const SectionsContainer = styled('div')`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(4)};
`;

type SwSectionReportProps = Partial<BasePlatformProps>;

const SwSectionScales = React.forwardRef<HTMLDivElement, SwSectionReportProps>((_props, ref) => {
    const smOrLess = useMediaQuery(useTheme().breakpoints.down('md'));
    const { t } = useTranslation();

    return (
        <div ref={ref}>
            <SwContainer withMaxWidth={true}>
                <SectionsContainer>
                    <StyledSwCard>
                        <StyledImage
                            alt={'sweep hill illustration'}
                            height={smOrLess ? '300px' : '335px'}
                            src={`https://sweep.imgix.net/platform/${smOrLess ? 'mobile' : 'desktop'}/hill_banner.jpg`}
                            width={smOrLess ? '300px' : '1040px'}
                        />
                        <Box left={'0'} p={3} position={'absolute'} top={'0'}>
                            <SwTypography color={black} component={'h2'} variant={'h3'}>
                                {t('platform_that_scales_with_you')}
                            </SwTypography>
                        </Box>
                    </StyledSwCard>

                    <GridContainer>
                        <SwCard background={grey7}>
                            <SwCard background={grey7} height={'100%'}>
                                <Box height={'100%'} p={2}>
                                    <SwTypography color={black} component={'h3'} variant={'h4'}>
                                        🙋
                                        <br />
                                        {t('platform_page.scales_section_card_1_title')}
                                    </SwTypography>
                                    <SwSpacer direction={'vertical'} spacing={1} />
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.scales_section_card_1_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </SwCard>

                        <SwCard background={grey7}>
                            <SwCard background={grey7} height={'100%'}>
                                <Box height={'100%'} p={2}>
                                    <SwTypography color={black} component={'h3'} variant={'h4'}>
                                        🎢
                                        <br />
                                        {t('platform_page.scales_section_card_2_title')}
                                    </SwTypography>
                                    <SwSpacer direction={'vertical'} spacing={1} />
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.scales_section_card_2_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </SwCard>

                        <SwCard background={grey7}>
                            <SwCard background={grey7} height={'100%'}>
                                <Box height={'100%'} p={2}>
                                    <SwTypography color={black} component={'h3'} variant={'h4'}>
                                        🔐
                                        <br />
                                        {t('platform_page.scales_section_card_3_title')}
                                    </SwTypography>
                                    <SwSpacer direction={'vertical'} spacing={1} />
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.scales_section_card_3_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </SwCard>

                        <SwCard background={grey7}>
                            <SwCard background={grey7} height={'100%'}>
                                <Box height={'100%'} p={2}>
                                    <SwTypography color={black} component={'h3'} variant={'h4'}>
                                        🎛️
                                        <br />
                                        {t('platform_page.scales_section_card_4_title')}
                                    </SwTypography>
                                    <SwSpacer direction={'vertical'} spacing={1} />
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.scales_section_card_4_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </SwCard>

                        <SwCard background={grey7}>
                            <SwCard background={grey7} height={'100%'}>
                                <Box height={'100%'} p={2}>
                                    <SwTypography color={black} component={'h3'} variant={'h4'}>
                                        📚
                                        <br />
                                        {t('platform_page.scales_section_card_5_title')}
                                    </SwTypography>
                                    <SwSpacer direction={'vertical'} spacing={1} />
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.scales_section_card_5_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </SwCard>

                        <SwCard background={grey7}>
                            <SwCard background={grey7} height={'100%'}>
                                <Box height={'100%'} p={2}>
                                    <SwTypography color={black} component={'h3'} variant={'h4'}>
                                        🚀
                                        <br />
                                        {t('platform_page.scales_section_card_6_title')}
                                    </SwTypography>
                                    <SwSpacer direction={'vertical'} spacing={1} />
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.scales_section_card_6_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </SwCard>
                    </GridContainer>
                </SectionsContainer>
            </SwContainer>
        </div>
    );
});

export default SwSectionScales;
