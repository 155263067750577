import React from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useTheme } from '@mui/material';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import { CDNClient } from '../../../lib/helpers/image';
import SwStoryCard from '../SwStoryCard';
import { BasePlatformProps } from './PlatformSections';

type SwSectionInvolveProps = BasePlatformProps;

const SwSectionInvolve = React.forwardRef<HTMLDivElement, SwSectionInvolveProps>((props, ref) => {
    const { details, onVisible, content } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <div ref={ref}>
            <SwContainer gap={theme.spacing(4)}>
                <InView
                    as={'div'}
                    id={details.key}
                    threshold={0.1}
                    onChange={(isInView) => {
                        if (isInView) {
                            onVisible(details);
                        }
                    }}
                >
                    <SwCmsRenderer content={content.children[0]} />
                </InView>
                <SwCmsRenderer content={content.children[1]} />
                <SwStoryCard
                    authorName={'Helen Nwosu'}
                    authorRole={t('platform_page.involve_story_card_author_role')}
                    buttonLabel={t('platform_page.involve_story_card_author_action_label')}
                    image={{
                        src: CDNClient.buildURL('/customers/prose/helen_nwosu.jpeg', { auto: 'format' }),
                        width: '520px',
                        height: '380px'
                    }}
                    logoSrc={CDNClient.buildURL('/logos/prose-logo.svg', { auto: 'format' })}
                    navigateTo={'/customers/prose'}
                    quote={t('platform_page.involve_story_card_author_quote')}
                />
            </SwContainer>
        </div>
    );
});

export default SwSectionInvolve;
