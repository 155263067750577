import { useEffect, useState } from 'react';

export const useReducedMotion = () => {
    const [reducedMotion, setReducedMotion] = useState<boolean | null>(null);

    useEffect(() => {
        const mql = window.matchMedia('(prefers-reduced-motion)');

        const handleMediaChange = (e: MediaQueryListEvent | MediaQueryList) => {
            setReducedMotion(e.matches);
        };

        handleMediaChange(mql);

        mql.addEventListener('change', handleMediaChange);

        return () => {
            mql.removeEventListener('change', handleMediaChange);
        };
    }, []);

    return reducedMotion;
};
