import React, { useEffect, useMemo, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { Box, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import ClimateTool, { ClimateMethodHandles } from 'sweep-journey';
import SwSweepJourneyController from '@lib/components/SwSweepJourneyControllers';
import Scene from '../../../assets/journey/models/gltf/scene.glb';
import Depth from '../../../assets/journey/textures/depth.png';
import ScannerIcons from '../../../assets/journey/textures/scanner-icons.png';
import SwContainer from '../../../components/v1/SwContainer';
import { TOP_NAV_HEIGHT } from '../../../components/v1/website/SwTopNav';
import { grey3, grey5, skyBlueStrong } from '../../../lib/components/bedrock/SwColors';
import SwSweepey from '../../../lib/components/bedrock/loader/SwSweepey';
import SwTypography from '../../../lib/components/bedrock/typography/SwTypography';
import { isBrowser } from '../../common/helpers/gatsby';
import { usePlatformContainer } from '../PlatformProvider';
import SwSubNavWebGL from '../SwSubNavWebGL';
import { PlatformSection } from '../reducers/platformReducer';

export interface SwHeaderProps {
    tabs: Array<PlatformSection>;
    title: string;
    subtitle: string;
    banner: { id: string };
}

const StyledBox = styled(Box)`
    height: calc(100vh - ${TOP_NAV_HEIGHT});
    min-width: 100%;
    position: relative;
    background-color: ${grey5};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
        & canvas {
            pointer-events: none;
            touch-action: none;
        }
    }
`;

const FlexBox = styled(Box)<{ $smOrLess: boolean }>`
    display: flex;
    justify-content: ${({ $smOrLess }) => ($smOrLess ? 'center' : 'unset')}%;
    width: ${({ $smOrLess }) => ($smOrLess ? 100 : 90)}%;
`;

const TopCard = styled(Box)<{ $smOrLess: boolean }>`
    background: rgba(249, 249, 249, 0.8);
    border: 1px solid ${grey5};
    width: ${({ $smOrLess }) => ($smOrLess ? '100%' : 'auto')};
    border-radius: 0 0 ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1)};
`;

const TopGrid = styled(Grid)<{ $smOrLess: boolean }>`
    width: ${({ $smOrLess }) => ($smOrLess ? '100%' : '360px')};
    background: white;
    border-radius: ${({ theme }) => theme.spacing(1)};
    border: 1px solid ${grey5};
    padding: ${({ theme }) => theme.spacing(2)};
    gap: ${({ theme }) => theme.spacing(2)};
`;

const ContentContainer = styled(SwContainer)`
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const StyledSwSweepJourneyController = styled(SwSweepJourneyController)`
    margin-top: 16px;
`;

const SwHeader: React.FC<SwHeaderProps> = (props) => {
    const [state, dispatch] = usePlatformContainer();
    const { climateTool } = state;

    const smOrLess = useMediaQuery(useTheme().breakpoints.down('md'));
    const smToLg = useMediaQuery(useTheme().breakpoints.between('sm', 'xl'));
    const lgOrMore = useMediaQuery(useTheme().breakpoints.up('lg'));

    const zoomSetting = useMemo(() => {
        // Happens on one render when switching from mobile to desktop view
        // and useMediaQuery returns false for all 3 breakpoints
        if (!smOrLess && !smToLg && !lgOrMore) {
            return { default: 3, max: 5, min: 3 };
        }

        return {
            ...(smOrLess && {
                default: 3,
                max: 5,
                min: 3
            }),
            ...(smToLg && {
                default: 3,
                max: 5,
                min: 3
            }),
            ...(lgOrMore && {
                default: 1.5,
                max: 5,
                min: 1.5
            })
        };
    }, [smOrLess, smToLg, lgOrMore]);

    const touchStartListener = (e: TouchEvent) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (isBrowser()) {
            dispatch({ type: 'updateZoomSetting', payload: zoomSetting });
            /** Adding  the Listener by hand because of passive property
             * of touchstart event that disable the capabilities of
             * preventDefault
             */
            const canvas = document.getElementById('experience');

            canvas?.addEventListener('touchstart', touchStartListener, { passive: false });

            return () => {
                canvas?.removeEventListener('touchstart', touchStartListener);
            };
        }
    }, [zoomSetting, dispatch]);

    const logoUrl = 'https://sweep.imgix.net/SweepLogo_Stripe_128px.png';
    const textures = { depth: Depth, scanner: ScannerIcons };
    const toolRef = useRef<ClimateMethodHandles>(null);
    const viewportOffset = isBrowser() && { x: window.innerWidth / 100, y: window.innerHeight / 100 };

    return (
        <>
            <StyledBox>
                <InView
                    as={'section'}
                    onChange={(isInView) => {
                        if (isInView) {
                            // Reset the activeArea and zoom when ClimateTool is visible
                            dispatch({ type: 'zoomReset' });
                        }
                    }}
                >
                    <ContentContainer disableGutterInXs={true} withMaxWidth={true}>
                        <FlexBox $smOrLess={smOrLess}>
                            <TopCard $smOrLess={smOrLess} p={2}>
                                <TopGrid $smOrLess={smOrLess} container={true}>
                                    <Grid item={true}>
                                        <SwTypography component={'h1'} variant={'h3'}>
                                            {props.title}
                                        </SwTypography>
                                    </Grid>
                                    <Grid item={true}>
                                        <SwTypography color={grey3} variant={'body1'}>
                                            {props.subtitle}
                                        </SwTypography>
                                    </Grid>
                                </TopGrid>
                            </TopCard>
                        </FlexBox>
                        {!smOrLess ? (
                            <StyledSwSweepJourneyController>
                                <SwSweepJourneyController.ZoomControls
                                    onZoomIn={() => dispatch({ type: 'zoomIn' })}
                                    onZoomOut={() => dispatch({ type: 'zoomOut' })}
                                />
                                <SwSweepJourneyController.ResetControl
                                    onReset={() => dispatch({ type: 'zoomReset' })}
                                />
                                <SwSweepJourneyController.SnowControls
                                    isSnowing={state.climateTool.snowMode}
                                    onSnowToggle={() => dispatch({ type: 'toggleSnowMode' })}
                                />
                            </StyledSwSweepJourneyController>
                        ) : null}
                    </ContentContainer>
                </InView>

                <Box
                    bgcolor={grey5}
                    bottom={0}
                    display={'grid'}
                    height={'100%'}
                    left={0}
                    position={'absolute'}
                    right={0}
                    style={{ placeItems: 'center' }}
                    top={0}
                    width={'100%'}
                    onMouseLeave={() => {
                        const ev = new Event('mouseup', { bubbles: true });
                        const c = document.getElementById('experience');
                        c?.dispatchEvent(ev);
                    }}
                >
                    {!state.climateTool.isInitialized ? <SwSweepey position={'absolute'} size={'30vw'} /> : null}
                    {viewportOffset && Scene ? (
                        <ClimateTool
                            assetPath={''}
                            backgroundColor={grey5}
                            brandColor={skyBlueStrong}
                            gltf={Scene}
                            industrySector={'sector0'}
                            logoUrl={logoUrl}
                            maxZoom={climateTool.zoomSetting.max}
                            minZoom={climateTool.zoomSetting.min}
                            paused={false}
                            ref={toolRef}
                            snowMode={state.climateTool.snowMode}
                            state={{ ...climateTool, activeArea: climateTool.activeArea?.areaNumber ?? 0 }}
                            textures={textures}
                            viewportOffset={viewportOffset}
                            zoom={climateTool.zoom}
                            onWebGLStarted={() => dispatch({ type: 'initializeClimateTool' })}
                            onZoom={(zoom) => dispatch({ type: 'onZoomChange', payload: zoom })}
                            // eslint-disable-next-line no-empty-function
                            onZoomTransitionEnd={() => {}}
                        />
                    ) : null}
                </Box>
            </StyledBox>
            <SwSubNavWebGL dispatch={dispatch} state={state} {...props} />
        </>
    );
};

export default SwHeader;
