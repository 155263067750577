import React, { useLayoutEffect, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { Box, BoxProps, styled, useMediaQuery, useTheme } from '@mui/material';
import { TOP_NAV_HEIGHT } from '../../components/v1/website/SwTopNav';
import { grey5 } from '../../lib/components/bedrock/SwColors';
import { DEFAULT_BANNER_HEIGHT } from '../../lib/components/bedrock/banner/SwBanner';
import SwSpacer from '../../lib/components/bedrock/layout/SwSpacer';
import { zIndexes } from '../../lib/enums/zIndexes';
import { useClickOutside } from '../../lib/hooks/useClickOutside';
import { useFlashBanner } from '../../lib/hooks/useFlashBanner';
import { useSnapScroll } from '../../lib/hooks/useSnapScroll';
import { isBrowser } from '../common/helpers/gatsby';
import { usePlatformContainer } from './PlatformProvider';
import SwSubNavArrow from './SwSubNavArrow';
import SwSubNavCard from './SwSubNavCard';
import { useSubNavCardsActions } from './hooks/useSubNavCardsActions';
import { PlatformActions, PlatformState } from './reducers/platformReducer';
import { SwHeaderProps } from './sections/SwHeader';

export interface SwSubNavWebGLProps extends SwHeaderProps {
    dispatch: React.Dispatch<PlatformActions>;
    state: PlatformState;
    banner: {
        id: string;
    };
}

export const SUB_NAV_HEIGHT_OPENED = 196;

const ScrollableBox = styled(Box)`
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: ${({ theme }) => theme.spacing(2)};
    transition-property: transform;
    transition-duration: 0.35s;
    transition-timing-function: ease-in-out;
    padding: var(--_scroll-container-gutter) var(--_scroll-container-gutter);
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const StickySubNavWrapper = styled(Box)<BoxProps & { hasBanner: boolean }>`
    --_container-gutter: 64px;
    --_scroll-container-gutter: ${({ theme }) => theme.spacing(2)};

    position: absolute;
    // Screen height - StickyBox - ArrowsBoxWrapper
    top: calc(
        100vh - (${SUB_NAV_HEIGHT_OPENED}px - ${({ hasBanner }) => (hasBanner ? `${DEFAULT_BANNER_HEIGHT}px` : '0px')}) -
            ${TOP_NAV_HEIGHT}
    );
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    pointer-events: none;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
        --_container-gutter: 20px;
    }
`;

const FixedContainer = styled(Box)<{ $smOrLess: boolean }>`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: ${TOP_NAV_HEIGHT};
    z-index: ${zIndexes.NAV_BAR_BELOW_APP_BAR};
    border-end-start-radius: 0;
    border-end-end-radius: 0;
    border-start-start-radius: ${({ $smOrLess }) => ($smOrLess ? 0 : useTheme().spacing(1))};
    border-start-end-radius: ${({ $smOrLess }) => ($smOrLess ? 0 : useTheme().spacing(1))};
    width: ${({ $smOrLess }) =>
        $smOrLess
            ? '100%'
            : `min(calc(1250px - (var(--_container-gutter) * 2)), calc(100% - (var(--_container-gutter) * 2)))`};
    border-inline: 1px solid ${grey5};
    border-block-start: 1px solid ${grey5};
    border-block-end: 1px solid ${grey5};
    background: rgba(249, 249, 249, 0.8);
    pointer-events: auto;
`;

const SubMenuItem = styled(Box)`
    scroll-snap-align: center;
`;

const ArrowsWrapper = styled(InView)`
    width: min(calc(1250px - (var(--_container-gutter) * 2)), calc(100% - (var(--_container-gutter) * 2)));
    display: flex;
    justify-content: end;
    padding-bottom: ${({ theme }) => theme.spacing(2)};
    pointer-events: auto;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        width: 100%;
    }
`;

const ArrowsBox = styled(Box)`
    display: flex;
    border-radius: ${({ theme }) => theme.spacing(1)};
    padding: ${({ theme }) => theme.spacing(1)};
    background: rgba(249, 249, 249, 0.8);
    border: 1px solid ${grey5};
`;

const SwSubNavWebGL: React.FC<SwSubNavWebGLProps> = ({ tabs, banner }) => {
    const [
        {
            climateTool: { activeArea }
        },
        dispatch
    ] = usePlatformContainer();
    const [showBanner] = useFlashBanner({ key: `flash-banner-${banner.id}`, initialValue: true });
    const topSubNavBoxRef = useRef();
    const expandedSubNavRef = useRef();
    const theme = useTheme();
    const smOrLess = useMediaQuery(theme.breakpoints.down('md'));

    const { scrollContainerProps, goTo } = useSnapScroll<HTMLDivElement>({ snapAlign: 'center' });
    const { handleCardActivation, nextCard, prevCard } = useSubNavCardsActions(tabs);

    useClickOutside(() => dispatch({ type: 'closeSubNav' }), {
        nodes: [topSubNavBoxRef.current, expandedSubNavRef.current]
    });

    /**
     * Calculate the scrollable height of the page.
     * @Max_page_height - @absolute_element_height
     */
    const scrollableHeight =
        isBrowser() &&
        `calc(${Math.max(
            document.body.getBoundingClientRect().height,
            document.documentElement.getBoundingClientRect().height
        )}px - 100vh
    )`;

    useLayoutEffect(() => {
        if (activeArea) {
            // Scroll to the active area card
            goTo(activeArea.number - 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeArea]);

    return (
        <StickySubNavWrapper hasBanner={showBanner} height={scrollableHeight}>
            <ArrowsWrapper rootMargin={`${SUB_NAV_HEIGHT_OPENED}px`} threshold={1}>
                <ArrowsBox>
                    <SwSubNavArrow direction={'left'} onClick={prevCard} />
                    <SwSpacer direction={'horizontal'} spacing={1} />
                    <SwSubNavArrow direction={'right'} onClick={nextCard} />
                </ArrowsBox>
            </ArrowsWrapper>

            <FixedContainer $smOrLess={smOrLess}>
                <ScrollableBox {...{ ...scrollContainerProps }}>
                    {tabs.map((tab) => (
                        <SubMenuItem key={`box-${tab.key}`}>
                            <SwSubNavCard details={tab} onActivateCard={handleCardActivation} />
                        </SubMenuItem>
                    ))}
                </ScrollableBox>
            </FixedContainer>
        </StickySubNavWrapper>
    );
};

export default SwSubNavWebGL;
