import { ArrowRight } from '@phosphor-icons/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import SwMediaCard from '@lib/components/SwMediaCard';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import SwCard from '../../../components/v1/SwCard';
import SwLine from '../../../components/v1/SwLine';
import SwVideoContainer from '../../../components/v1/SwVideoContainer';
import { black, grey3, grey5, grey7, white } from '../../../lib/components/bedrock/SwColors';
import SwTypography from '../../../lib/components/bedrock/typography/SwTypography';
import SwStoryCard from '../SwStoryCard';
import { BasePlatformProps } from './PlatformSections';

type SwSectionCollaborateProps = BasePlatformProps;

const SwSectionCollaborate = React.forwardRef<HTMLDivElement, SwSectionCollaborateProps>((props, ref) => {
    const smOrLess = useMediaQuery(useTheme().breakpoints.down('md'));
    const theme = useTheme();
    const { details, onVisible, content } = props;
    const { t } = useTranslation();

    return (
        <div ref={ref}>
            <SwContainer gap={theme.spacing(4)}>
                <InView
                    as={'div'}
                    id={details.key}
                    threshold={0.1}
                    onChange={(isInView) => {
                        if (isInView) {
                            onVisible(details);
                        }
                    }}
                >
                    <SwCmsRenderer content={content.children[0]} />
                </InView>
                <SwCmsRenderer content={content.children[1]} />
                <SwMediaCard
                    background={white}
                    height={'fit-content'}
                    layout={'content-bottom'}
                    media={
                        <SwVideoContainer
                            autoPlay={true}
                            height={'1206px'}
                            loop={true}
                            muted={true}
                            playCtrl={true}
                            src={`https://sweep.imgix.net/platform/${smOrLess ? 'mobile' : 'desktop'}/features.mp4`}
                            useHasChild={true}
                            width={'1872px'}
                        />
                    }
                    mediaContainerStyle={{
                        overflow: 'unset'
                    }}
                >
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={12}>
                            <SwTypography component={'h3'} variant={'h4'}>
                                {t('platform_page.collaborate_feature_card_title')}
                            </SwTypography>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <SwTypography color={grey3} variant={'body1'}>
                                {t('platform_page.collaborate_feature_card_content')}
                            </SwTypography>
                        </Grid>
                        <Grid item={true} md={3}>
                            <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                <Box p={2}>
                                    <SwTypography color={black} component={'h4'} variant={'h5'}>
                                        ⚙️ {t('platform_page.collaborate_feature_card_children_1_title')}
                                    </SwTypography>
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.collaborate_feature_card_children_1_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </Grid>
                        <Grid item={true} md={3}>
                            <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                <Box p={2}>
                                    <SwTypography color={black} component={'h4'} variant={'h5'}>
                                        📔 {t('platform_page.collaborate_feature_card_children_2_title')}
                                    </SwTypography>
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.collaborate_feature_card_children_2_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </Grid>
                        <Grid item={true} md={3}>
                            <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                <Box p={2}>
                                    <SwTypography color={black} component={'h4'} variant={'h5'}>
                                        📬 {t('platform_page.collaborate_feature_card_children_3_title')}
                                    </SwTypography>
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.collaborate_feature_card_children_3_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </Grid>
                        <Grid item={true} md={3}>
                            <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                <Box p={2}>
                                    <SwTypography color={black} component={'h4'} variant={'h5'}>
                                        📐 {t('platform_page.collaborate_feature_card_children_4_title')}
                                    </SwTypography>
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.collaborate_feature_card_children_4_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </Grid>
                    </Grid>
                    <SwLine color={grey5} spacing={2} />
                    <Box display={'flex'} justifyContent={'start'}>
                        <SwInternalLink to={'/finance'} variant={'secondary'}>
                            <ArrowRight /> {t('platform_page.collaborate_feature_card_action_label')}
                        </SwInternalLink>
                    </Box>
                </SwMediaCard>

                <SwStoryCard
                    authorName={'Res Witschi'}
                    authorRole={t('platform_page.collaborate_story_card_author_role')}
                    buttonLabel={t('platform_page.collaborate_story_card_author_action_label')}
                    image={{
                        src: 'https://sweep.imgix.net/platform/res_witshi.jpg',
                        width: '520px',
                        height: '390px'
                    }}
                    navigateTo={'/customers/swisscom'}
                    quote={t('platform_page.collaborate_story_card_author_quote')}
                />
            </SwContainer>
        </div>
    );
});

export default SwSectionCollaborate;
