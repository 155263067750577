import React from 'react';
import { InView } from 'react-intersection-observer';
import { useTheme } from '@mui/material';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import { BasePlatformProps } from './PlatformSections';

type SwSectionBuildProps = BasePlatformProps;

const SwSectionBuild = React.forwardRef<HTMLDivElement, SwSectionBuildProps>((props, ref) => {
    const theme = useTheme();
    const { details, onVisible, content } = props;

    return (
        <div ref={ref}>
            <SwContainer gap={theme.spacing(4)}>
                <InView
                    as={'div'}
                    id={details.key}
                    threshold={0.1}
                    onChange={(isInView) => {
                        if (isInView) {
                            onVisible(details);
                        }
                    }}
                >
                    <SwCmsRenderer content={content.children[0]} />
                </InView>
                <SwCmsRenderer content={content.children.slice(1, 3)} />
            </SwContainer>
        </div>
    );
});

export default SwSectionBuild;
