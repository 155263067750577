import { RefObject, UIEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useDebounceResizeObserver } from './useDebounceResizeObserver';
import { useReducedMotion } from './useReduceMotion';

export type SnapAlign = 'center';

export type UseSnapScrollArgs = {
    snapAlign: SnapAlign;
};

export type ScrollContainerProps<T extends HTMLElement> = {
    ref: RefObject<T>;
    onScroll: UIEventHandler<HTMLElement>;
    'data-scroll-container': true;
};

export type UseSnapScrollReturn<T extends HTMLElement> = {
    // Will scroll to the closest next element
    goNext: () => void;
    // Will scroll to the closest previous element
    goPrev: () => void;
    // Will scroll to the given child element
    goTo: (index: number) => void;
    // Props that will allow this hook to work. You will have to apply this to the scroll container element
    scrollContainerProps: ScrollContainerProps<T>;
};

/**
 * Tell if the parent has the attribute [data-scroll-container="true"]
 * @param element Html element
 * @returns
 */
const isScrollContainer = (element: HTMLElement): boolean => Boolean(element.dataset['scrollContainer']);

/**
 * Will calculate the "X" position of the scroll snap point within the given box element.
 * @param element Box element
 * @param param1 Options
 * @returns
 */
const getScrollSnapPoint = (
    element?: HTMLElement | null,
    options?: { snapAlign: SnapAlign; containerSize?: number }
) => {
    if (!element) {
        return 0;
    }

    switch (options?.snapAlign || 'center') {
        case 'center':
            return Math.floor(
                isScrollContainer(element)
                    ? element.scrollLeft + (options?.containerSize ?? element.offsetWidth) / 2
                    : element.offsetLeft + element.offsetWidth / 2
            );
    }
};

const logErrorWhenMissingRef = () => {
    console.error(
        'Scroll container ref is not available. Do not forget to pass the scrollContainerProps to the container element.'
    );
};

export const useSnapScroll = <T extends HTMLElement>({ snapAlign }: UseSnapScrollArgs): UseSnapScrollReturn<T> => {
    const scrollContainerRef = useRef<T>(null);
    const [containerSize, setContainerSize] = useState(0);
    const shouldReduceMotion = useReducedMotion();
    const [containerSnapPoint, setContainerSnapPoint] = useState(
        getScrollSnapPoint(scrollContainerRef.current, { snapAlign })
    );

    const childrenSnapPoints = useMemo(
        () =>
            scrollContainerRef.current
                ? Array.from(scrollContainerRef.current.children)
                      .map((childNode) => getScrollSnapPoint(childNode as HTMLElement, { snapAlign, containerSize }))
                      .sort((a, b) => a - b)
                : [],
        [snapAlign, containerSize]
    );
    const reversedChildrenSnapPoints = useMemo(() => [...childrenSnapPoints].reverse(), [childrenSnapPoints]);

    const goNext = useCallback(() => {
        // Return closest snap point. If last point return the point itself
        const nextSnapPoint = childrenSnapPoints.find((position, index, array) => {
            if (index === array.length - 1 || (index === 0 && position > containerSnapPoint)) {
                return true;
            }

            return position > containerSnapPoint && array[index - 1] <= containerSnapPoint;
        });
        const scrollDistance = nextSnapPoint - containerSnapPoint;

        if (!scrollContainerRef.current) {
            logErrorWhenMissingRef();
        }

        scrollContainerRef.current?.scrollBy({
            left: scrollDistance,
            behavior: shouldReduceMotion ? undefined : 'smooth'
        });
    }, [childrenSnapPoints, containerSnapPoint, shouldReduceMotion]);

    const goPrev = useCallback(() => {
        // Return closest snap point. If last point return the point itself
        const prevSnapPoint = reversedChildrenSnapPoints.find((position, index, array) => {
            if (index === array.length - 1 || (index === 0 && position < containerSnapPoint)) {
                return true;
            }

            return position < containerSnapPoint && array[index - 1] >= containerSnapPoint;
        });
        const scrollDistance = prevSnapPoint - containerSnapPoint;

        if (!scrollContainerRef.current) {
            logErrorWhenMissingRef();
        }

        scrollContainerRef.current?.scrollBy({
            left: scrollDistance,
            behavior: shouldReduceMotion ? undefined : 'smooth'
        });
    }, [reversedChildrenSnapPoints, containerSnapPoint, shouldReduceMotion]);

    const goTo = useCallback(
        (index: number) => {
            const snapPoint = childrenSnapPoints[index];

            if (!snapPoint) {
                return;
            }

            const scrollDistance = snapPoint - containerSnapPoint;

            if (!scrollContainerRef.current) {
                logErrorWhenMissingRef();
            }
            scrollContainerRef.current?.scrollBy({
                left: scrollDistance,
                behavior: shouldReduceMotion ? undefined : 'smooth'
            });
        },
        [childrenSnapPoints, containerSnapPoint, shouldReduceMotion]
    );

    const syncScroll: UIEventHandler<HTMLElement> = debounce((event) => {
        setContainerSnapPoint(getScrollSnapPoint(event.target as HTMLElement, { snapAlign }));
    }, 100);

    const calculateContainerSizeAndSnapPoint = (width?: number) => {
        if (width) {
            setContainerSnapPoint(getScrollSnapPoint(scrollContainerRef.current, { snapAlign }));
            setContainerSize(Math.floor(width));
        }
    };

    useDebounceResizeObserver({
        ref: scrollContainerRef,
        onResize: ({ width }) => calculateContainerSizeAndSnapPoint(width),
        wait: 200,
        box: 'border-box'
    });

    useEffect(() => {
        if (scrollContainerRef.current) {
            setContainerSize(scrollContainerRef.current?.getBoundingClientRect().width);
            setContainerSnapPoint(getScrollSnapPoint(scrollContainerRef.current, { snapAlign }));
        }
    }, [snapAlign]);

    return {
        goPrev,
        goNext,
        goTo,
        scrollContainerProps: {
            ref: scrollContainerRef,
            onScroll: syncScroll,
            'data-scroll-container': true
        }
    };
};
