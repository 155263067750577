import { useMemo } from 'react';
import debounce from 'lodash/debounce';
import useResizeObserver from 'use-resize-observer';

export type UseDebounceResizeObserverParameters = Parameters<typeof useResizeObserver>[0] & {
    wait: number;
};

export const useDebounceResizeObserver = ({
    wait,
    onResize: onOriginalResize,
    ...rest
}: UseDebounceResizeObserverParameters) => {
    const onResize = useMemo(() => debounce(onOriginalResize, wait, { leading: true }), [wait, onOriginalResize]);
    const result = useResizeObserver({ onResize, ...rest });

    return result;
};
