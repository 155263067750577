import { ArrowCounterClockwise, ArrowsOut, Pause, Play, SpeakerHigh, SpeakerSlash } from '@phosphor-icons/react';
import React, { MediaHTMLAttributes, useCallback, useRef, useState, VideoHTMLAttributes } from 'react';
import { Grid, useTheme, styled } from '@mui/material';
import { grey5, grey6 } from '../../lib/components/bedrock/SwColors';
import { SwButton, SwButtonKind } from '../../lib/components/bedrock/button/SwButton';
import { iOSDetected } from '../../lib/helpers/iOSDetector';
import { playVideoFullScreen } from '../../lib/helpers/video';

/**
 * Be sure to define the @width and @height of your video
 * so that the browser can allocate the necessary bandwidth with no extra work.
 * To redifine the size of your video then use @maxWidth @maxHeight
 */

type Video = MediaHTMLAttributes<HTMLVideoElement> & VideoHTMLAttributes<HTMLVideoElement>;

export interface SwVideoContainerProps extends Video {
    allControls?: boolean;
    audioCtrl?: boolean;
    controls?: boolean;
    playCtrl?: boolean;
    sizeCtrl?: boolean;
    useHasChild?: boolean;
    maxWidth?: string;
    maxHeight?: string;
    width: string;
    height: string;
}

interface StyledVideoProps {
    $useHasChild: boolean;
    maxWidth?: string;
    maxHeight?: string;
    width: string;
    height: string;
}

const VideoContainer = styled(Grid)<{ $useHasChild: boolean }>`
    border-radius: ${({ $useHasChild }) => (!$useHasChild ? useTheme().spacing(2) : 'unset')};
    background: inherit;
    position: relative;
    block-size: 100%;
`;

const StyledVideo = styled('video')<StyledVideoProps>`
    width: 100%;
    height: 100%;
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '100%')};
    max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '100%')};
    object-fit: cover;
    border-radius: ${({ $useHasChild }) => (!$useHasChild ? useTheme().spacing(2) : 'unset')};
    border: ${({ $useHasChild }) => (!$useHasChild ? '1px solid' + grey5 : 'unset')};
`;

const StyledControls = styled(Grid)`
    position: absolute;
    left: 16px;
    bottom: 16px;
    min-height: 30px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;
    background-color: ${grey6}CC;
    border: 1px solid ${grey6};
`;

const SwVideoContainer: React.FunctionComponent<SwVideoContainerProps> = (props) => {
    const { src, audioCtrl, sizeCtrl, playCtrl, controls, autoPlay, allControls, useHasChild } = props;
    // iOS devices doesn't support the custom size controller
    const disableCustomControllers = !controls && sizeCtrl ? iOSDetected() : controls;
    const video = useRef<HTMLVideoElement>(null);
    const [isMuted, setIsMuted] = useState(true);
    const [isPlaying, setIsPlaying] = useState(autoPlay);
    const [hasEnded, setHasEnded] = useState(false);

    const togglePlay = useCallback(() => {
        if (hasEnded) {
            setHasEnded(false);
        }
        video.current.paused ? video.current.play() : video.current.pause();
        setIsPlaying(!isPlaying);
    }, [isPlaying, hasEnded]);

    const toggleSound = useCallback(() => {
        video.current.muted = !video.current.muted;
        setIsMuted(video.current.muted);
    }, []);

    const toggleFullScreen = useCallback(() => {
        playVideoFullScreen(video.current);
    }, []);

    return (
        <VideoContainer $useHasChild={useHasChild} item={true} xs={12}>
            <StyledVideo
                $useHasChild={useHasChild}
                autoPlay={autoPlay}
                controls={disableCustomControllers}
                muted={true}
                ref={video}
                src={src}
                onEnded={() => setHasEnded(true)}
                onPause={() => setIsPlaying(false)}
                onPlay={() => setIsPlaying(true)}
                {...props}
            />
            {!disableCustomControllers && (allControls || audioCtrl || sizeCtrl || playCtrl) ? (
                <StyledControls>
                    {sizeCtrl || allControls ? (
                        <SwButton
                            ariaLabel={'toggle full screen'}
                            kind={SwButtonKind.Secondary}
                            size={'small'}
                            onClick={toggleFullScreen}
                        >
                            <ArrowsOut size={18} />
                        </SwButton>
                    ) : null}

                    {audioCtrl || allControls ? (
                        <SwButton
                            ariaLabel={'toggle sound'}
                            kind={SwButtonKind.Secondary}
                            size={'small'}
                            onClick={toggleSound}
                        >
                            {isMuted ? <SpeakerSlash size={18} /> : <SpeakerHigh size={18} />}
                        </SwButton>
                    ) : null}

                    {playCtrl || allControls ? (
                        <SwButton
                            ariaLabel={'toggle sound'}
                            kind={SwButtonKind.Secondary}
                            size={'small'}
                            onClick={togglePlay}
                        >
                            {hasEnded ? (
                                <ArrowCounterClockwise size={18} />
                            ) : isPlaying ? (
                                <Pause size={18} />
                            ) : (
                                <Play size={18} />
                            )}
                        </SwButton>
                    ) : null}
                </StyledControls>
            ) : null}
        </VideoContainer>
    );
};

export default SwVideoContainer;
