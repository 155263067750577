import React, { memo } from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import { CDNClient } from '../../../helpers/image';

export type SwSweepeyProps = BoxProps & {
    size?: string;
};

const Container = styled(Box)`
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
`;

const StyledImg = styled('img')<Pick<SwSweepeyProps, 'size'>>`
    aspect-ratio: 1/1;
    height: ${({ size }) => size};
    width: ${({ size }) => size};
`;

const SwSweepey: React.FC<SwSweepeyProps> = memo(({ size = '250px', ...rest }) => (
    <Container {...rest}>
        <StyledImg
            alt={'Sweepey'}
            height={size}
            size={size}
            src={CDNClient.buildURL('/logos/Sweepey.gif')}
            width={size}
        />
    </Container>
));

export default SwSweepey;
