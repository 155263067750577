import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, styled } from '@mui/material';
import SwCard, { SwCardProps } from '../../components/v1/SwCard';
import { white, grey3, grey5, skyBlueStrong, grey7 } from '../../lib/components/bedrock/SwColors';
import SwTypography from '../../lib/components/bedrock/typography/SwTypography';
import { usePlatformContainer } from './PlatformProvider';
import SwSectionNumber from './SwSectionNumber';
import { PlatformSection } from './reducers/platformReducer';
import { SwTooltip } from '../../components/v1/SwTooltip';

interface SwSubNavCardProps {
    details: PlatformSection;
    onActivateCard: (details: PlatformSection) => void;
}

interface CustomSwCardProps extends Omit<SwCardProps, 'onClick'> {
    onClick: (e: Event) => void;
    isActive?: boolean;
}

const StyledGrid = styled(Grid)`
    display: flex;
    align-items: center;
`;

const CustomSwCard = styled(SwCard)<CustomSwCardProps>`
    width: 360px;
    cursor: pointer;
    border: ${(props) => (props.isActive ? `1px solid ${skyBlueStrong}` : `1px solid ${grey5}`)};

    &:hover {
        background-color: ${grey7};
    }
`;

const Description = styled(SwTypography)`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const SwSubNavCard: React.FC<SwSubNavCardProps> = (props) => {
    const {
        details: { description, number, title, key },
        onActivateCard
    } = props;
    const { t } = useTranslation();

    const [
        {
            climateTool: { activeArea }
        }
    ] = usePlatformContainer();

    const ref = useRef<HTMLDivElement>();

    return (
        <CustomSwCard
            background={white}
            isActive={activeArea?.key === key}
            ref={ref}
            onClick={() => onActivateCard(props.details)}
        >
            <Box height={160} p={2}>
                <Grid container={true} spacing={2}>
                    <Grid container={true} item={true} spacing={1}>
                        <StyledGrid item={true} xs={12}>
                            <SwSectionNumber box={{ mr: 2 }} height={24} sm={true} width={24}>
                                {number}
                            </SwSectionNumber>
                            <SwTypography
                                component={'p'}
                                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                variant={'h5'}
                            >
                                {t(title)}
                            </SwTypography>
                        </StyledGrid>
                        <Grid item={true} xs={12}>
                            <SwTooltip title={t(description)}>
                                <Description color={grey3} variant={'body2'}>
                                    {t(description)}
                                </Description>
                            </SwTooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </CustomSwCard>
    );
};

export default SwSubNavCard;
