import React from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { Box, Grid, useTheme } from '@mui/material';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwCard from '../../../components/v1/SwCard';
import { black, grey3, grey5, grey7, white } from '../../../lib/components/bedrock/SwColors';
import SwTypography from '../../../lib/components/bedrock/typography/SwTypography';
import { BasePlatformProps } from './PlatformSections';

type SwSectionUnderstandProps = BasePlatformProps;

const SwSectionUnderstand = React.forwardRef<HTMLDivElement, SwSectionUnderstandProps>((props, ref) => {
    const theme = useTheme();
    const { details, onVisible, content } = props;

    const { t } = useTranslation();

    return (
        <div ref={ref}>
            <SwContainer gap={theme.spacing(4)}>
                <Box pt={4}>
                    <InView
                        as={'div'}
                        id={details.key}
                        threshold={0.1}
                        onChange={(isInView) => {
                            if (isInView) {
                                onVisible(details);
                            }
                        }}
                    >
                        <SwCmsRenderer content={content.children[0]} />
                    </InView>
                </Box>

                <SwCmsRenderer content={content.children[1]} />

                <SwCard background={white} style={{ paddingInline: theme.spacing(3), paddingBlock: theme.spacing(2) }}>
                    <Grid container={true} spacing={2}>
                        <Grid item={true} xs={12}>
                            <SwTypography component={'h3'} variant={'h4'}>
                                {t('platform_page.understand_section_section_2_title')}
                            </SwTypography>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <SwTypography color={grey3} variant={'body1'}>
                                {t('platform_page.understand_section_section_2_subtitle')}
                            </SwTypography>
                        </Grid>
                        <Grid item={true} md={4}>
                            <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                <Box p={2}>
                                    <SwTypography color={black} component={'h4'} variant={'h5'}>
                                        👀 {t('platform_page.understand_section_card_1_title')}
                                    </SwTypography>
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.understand_section_card_1_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </Grid>
                        <Grid item={true} md={4}>
                            <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                <Box p={2}>
                                    <SwTypography color={black} component={'h4'} variant={'h5'}>
                                        🙋 {t('platform_page.understand_section_card_2_title')}
                                    </SwTypography>
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.understand_section_card_2_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </Grid>
                        <Grid item={true} md={4}>
                            <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                <Box p={2}>
                                    <SwTypography color={black} component={'h4'} variant={'h5'}>
                                        🧮 {t('platform_page.understand_section_card_3_title')}
                                    </SwTypography>
                                    <SwTypography color={grey3} variant={'body2'}>
                                        {t('platform_page.understand_section_card_2_content')}
                                    </SwTypography>
                                </Box>
                            </SwCard>
                        </Grid>
                    </Grid>
                </SwCard>
            </SwContainer>
        </div>
    );
});

export default SwSectionUnderstand;
