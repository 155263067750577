import { ArrowRight } from '@phosphor-icons/react';
import React, { ImgHTMLAttributes } from 'react';
import { Box, styled } from '@mui/material';
import { navigate } from 'gatsby';
import { white, black, grey2, grey5 } from '../../lib/components/bedrock/SwColors';
import SwButton, { SwButtonKind } from '../../lib/components/bedrock/button/SwButton';
import SwTypography from '../../lib/components/bedrock/typography/SwTypography';

interface SwStoryCardProps {
    authorName: string;
    authorRole: string;
    buttonLabel: string;
    image: ImgHTMLAttributes<HTMLImageElement>;
    logoSrc?: string;
    navigateTo: string;
    quote: string;
}

const FlexColumnBox = styled(Box)`
    display: flex;
    flex-direction: column;
`;

const StyledImage = styled('img')`
    height: 100%;
    width: 100%;
`;

const LogoContainer = styled('div')`
    position: absolute;
    bottom: ${({ theme }) => theme.spacing(2)};
    left: ${({ theme }) => theme.spacing(2)};
    background-color: white;
    border-radius: ${({ theme }) => theme.spacing(1)};
    overflow: hidden;
    z-index: 2;
    border: 1px solid ${grey5};
    max-block-size: 64px;
`;

const Card = styled('div')<{ interactive?: boolean }>`
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    z-index: 0;
    display: grid;
    border: 1px solid ${grey5};
    border-radius: 8px;
    grid-template-columns: 45% 55%;
    min-height: 380px;
    grid-template-rows: 1fr;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
    }
`;

const ContentContainer = styled('div')`
    background: ${white};
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
    border-left: 1px solid ${grey5};
    border-bottom: 0;

    & > p:first-child {
        flex: 1;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        border-left: 0;
        border-bottom: 1px solid ${grey5};
    }
`;

const ImageContainer = styled('div')`
    overflow: hidden;
    position: relative;
    background: ${grey5};
    z-index: 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        aspect-ratio: 4/3;
    }
`;

const SwStoryCard: React.FC<SwStoryCardProps> = (props) => {
    const { authorName, authorRole, buttonLabel, image, navigateTo, quote, logoSrc } = props;

    return (
        <Card>
            <ImageContainer>
                <StyledImage alt={authorName} {...image} />
                {logoSrc ? (
                    <LogoContainer>
                        <img alt={"Company's logo"} height={64} loading={'lazy'} src={logoSrc} width={103} />
                    </LogoContainer>
                ) : null}
            </ImageContainer>

            <ContentContainer>
                <FlexColumnBox>
                    <SwTypography color={black} paragraph={true} variant={'h4'}>
                        {quote}
                    </SwTypography>
                    <FlexColumnBox justifyContent={'center'} pb={2}>
                        <SwTypography bold={true} color={grey2} variant={'body2'}>
                            {authorName}
                        </SwTypography>
                        <SwTypography color={grey2} variant={'body2'}>
                            {authorRole}
                        </SwTypography>
                    </FlexColumnBox>
                </FlexColumnBox>
                <Box alignSelf={'baseline'}>
                    <SwButton
                        ariaLabel={buttonLabel}
                        disabled={false}
                        kind={SwButtonKind.Secondary}
                        startIcon={<ArrowRight />}
                        onClick={() => navigate(navigateTo)}
                    >
                        {buttonLabel}
                    </SwButton>
                </Box>
            </ContentContainer>
        </Card>
    );
};

export default SwStoryCard;
