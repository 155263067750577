import React from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useTheme } from '@mui/material';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import SwMediaCard from '@lib/components/SwMediaCard';
import { black, grey3, white } from '@lib/components/bedrock/SwColors';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwSpacer from '@lib/components/bedrock/layout/SwSpacer';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { CDNClient } from '@lib/helpers/image';
import SwLink from '../../../components/v1/SwLink';
import ResponsiveImg from '../../common/components/ResponsiveImg';
import SwStoryCard from '../SwStoryCard';
import { BasePlatformProps } from './PlatformSections';

type SwSectionContributionsProps = BasePlatformProps;

const SwSectionContributions = React.forwardRef<HTMLDivElement, SwSectionContributionsProps>((props, ref) => {
    const { details, onVisible, content } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <div ref={ref}>
            <SwContainer gap={theme.spacing(4)}>
                <InView
                    as={'div'}
                    id={details.key}
                    threshold={0.1}
                    onChange={(isInView) => {
                        if (isInView) {
                            onVisible(details);
                        }
                    }}
                >
                    <SwCmsRenderer content={content.children[0]} />
                </InView>
                <SwCmsRenderer content={content.children[1]} />
                <SwMediaCard
                    background={white}
                    layout={'content-left'}
                    media={
                        <ResponsiveImg
                            alt={'Contributions grid'}
                            loading={'lazy'}
                            srcSet={{
                                xs: {
                                    src: CDNClient.buildURL('/contributions/contributions_grid.png', {
                                        auto: 'format',
                                        w: 0.5
                                    }),
                                    height: 196,
                                    width: 348
                                },
                                sm: {
                                    src: CDNClient.buildURL('/contributions/contributions_grid.png', {
                                        auto: 'format'
                                    }),
                                    height: 409,
                                    width: 728
                                }
                            }}
                        />
                    }
                >
                    <SwTypography color={black} component={'h3'} variant={'h4'}>
                        {t('platform_page.contributions_section_card_title')}
                    </SwTypography>
                    <SwSpacer direction={'vertical'} spacing={2} />
                    <SwTypography color={grey3} paragraph={true} variant={'body1'}>
                        {t('platform_page.contributions_section_card_content')}
                    </SwTypography>
                    <SwLink color={grey3} href={'/catalogue'} underlined={true} variant={'body1'}>
                        {t('platform_page.contributions_section_card_action_label')}
                    </SwLink>
                </SwMediaCard>
                <SwStoryCard
                    authorName={'Nicolas Hunsinger'}
                    authorRole={t('platform_page.contributions_story_card_author_role')}
                    buttonLabel={t('platform_page.contributions_story_card_author_action_label')}
                    image={{
                        src: 'https://sweep.imgix.net/platform/nicolas_hunsinger.jpg',
                        width: '520px',
                        height: '390px'
                    }}
                    navigateTo={'/customers/ubisoft'}
                    quote={t('platform_page.contributions_story_card_author_quote')}
                />
            </SwContainer>
        </div>
    );
});

export default SwSectionContributions;
