import React, { Fragment, useMemo } from 'react';
import debounce from 'lodash/debounce';
import WebsiteSectionsLayout from '../../common/components/WebisteSectionsLayout';
import { usePlatformContainer } from '../PlatformProvider';
import { PlatformSection } from '../reducers/platformReducer';
import SwSectionBuild from './SwSectionBuild';
import SwSectionCollaborate from './SwSectionCollaborate';
import SwSectionContributions from './SwSectionContributions';
import SwSectionFeedback from './SwSectionFeedback';
import SwSectionInvolve from './SwSectionInvolve';
import SwSectionOnTarget from './SwSectionOnTarget';
import SwSectionPrecision from './SwSectionPrecision';
import SwSectionReport from './SwSectionReport';
import SwSectionScales from './SwSectionScales';
import SwSectionUnderstand from './SwSectionUnderstand';

export type PlatformSectionsProps = {
    tabs: PlatformSection[];
    sections: any[];
};

export type BasePlatformProps = {
    onVisible: (details: PlatformSection) => void;
    details: PlatformSection;
    content?: any;
};

const PlatformSectionsComponents = [
    SwSectionUnderstand,
    SwSectionBuild,
    SwSectionFeedback,
    SwSectionPrecision,
    SwSectionInvolve,
    SwSectionCollaborate,
    SwSectionOnTarget,
    SwSectionContributions,
    SwSectionReport,
    SwSectionScales
];

const PlatformSections: React.FC<PlatformSectionsProps> = ({ tabs, sections }) => {
    const [state, dispatch] = usePlatformContainer();

    const updateState = useMemo(
        () =>
            debounce((tab: PlatformSection) => {
                if (state.isScrollingToTarget) {
                    dispatch({ type: 'reachedTarget' });
                } else {
                    dispatch({
                        type: 'changeActiveArea',
                        payload: { activeArea: tab, isScrollingToTarget: false }
                    });
                }
            }, 200),
        [state, dispatch]
    );

    return (
        <WebsiteSectionsLayout>
            {PlatformSectionsComponents.map((Section, index) => (
                <Fragment key={`section-${index}`}>
                    <Section content={sections[index]} details={tabs[index]} onVisible={updateState} />
                </Fragment>
            ))}
        </WebsiteSectionsLayout>
    );
};

export default PlatformSections;
