import React from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useTheme } from '@mui/material';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwStoryCard from '../SwStoryCard';
import { BasePlatformProps } from './PlatformSections';

type SwSectionFeedbackProps = BasePlatformProps;

const SwSectionFeedback = React.forwardRef<HTMLDivElement, SwSectionFeedbackProps>((props, ref) => {
    const { details, onVisible, content } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <div ref={ref}>
            <SwContainer gap={theme.spacing(4)}>
                <InView
                    as={'div'}
                    id={details.key}
                    threshold={0.1}
                    onChange={(isInView) => {
                        if (isInView) {
                            onVisible(details);
                        }
                    }}
                >
                    <SwCmsRenderer content={content.children[0]} />
                </InView>
                <SwCmsRenderer content={content.children[1]} />
                <SwStoryCard
                    authorName={'Pascal Magnier'}
                    authorRole={t('platform_page.feedback_story_card_author_role')}
                    buttonLabel={t('platform_page.feedback_story_card_author_action_label')}
                    image={{
                        src: 'https://sweep.imgix.net/platform/pascal_magnier.jpg',
                        width: '520px',
                        height: '390px'
                    }}
                    navigateTo={'/customers/questel'}
                    quote={t('platform_page.feedback_story_card_author_quote')}
                />
            </SwContainer>
        </div>
    );
});

export default SwSectionFeedback;
