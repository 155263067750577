import React from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { Box, Grid, useTheme } from '@mui/material';
import { SwCmsRenderer } from '@lib/components/SwCmsRenderer';
import { SwContainer } from '@lib/components/bedrock/layout/SwContainer';
import SwCard from '../../../components/v1/SwCard';
import { black, grey3, grey5, grey7, white } from '../../../lib/components/bedrock/SwColors';
import SwTypography from '../../../lib/components/bedrock/typography/SwTypography';
import { BasePlatformProps } from './PlatformSections';

type SwSectionPrecisionProps = BasePlatformProps;

const SwSectionPrecision = React.forwardRef<HTMLDivElement, SwSectionPrecisionProps>((props, ref) => {
    const { details, onVisible, content } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <div ref={ref}>
            <SwContainer gap={theme.spacing(4)}>
                <InView
                    as={'div'}
                    id={details.key}
                    threshold={0.1}
                    onChange={(isInView) => {
                        if (isInView) {
                            onVisible(details);
                        }
                    }}
                >
                    <SwCmsRenderer content={content.children[0]} />
                </InView>
                <SwCmsRenderer content={content.children[1]} />
                <SwCard background={white}>
                    <Box px={3} py={2}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12}>
                                <SwTypography component={'h3'} variant={'h4'}>
                                    {t('platform_page.precision_section_card_1_title')}
                                </SwTypography>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwTypography color={grey3} variant={'body1'}>
                                    {t('platform_page.precision_section_card_1_content')}
                                </SwTypography>
                            </Grid>
                            <Grid item={true} md={4} xs={12}>
                                <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                    <Box p={2}>
                                        <SwTypography color={black} component={'h4'} variant={'h5'}>
                                            💨 {t('platform_page.precision_section_card_1_sub_card_1_title')}
                                        </SwTypography>
                                        <SwTypography color={grey3} variant={'body2'}>
                                            {t('platform_page.precision_section_card_1_sub_card_1_content')}
                                        </SwTypography>
                                    </Box>
                                </SwCard>
                            </Grid>
                            <Grid item={true} md={4} xs={12}>
                                <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                    <Box p={2}>
                                        <SwTypography color={black} component={'h4'} variant={'h5'}>
                                            〽️ {t('platform_page.precision_section_card_1_sub_card_2_title')}
                                        </SwTypography>
                                        <SwTypography color={grey3} variant={'body2'}>
                                            {t('platform_page.precision_section_card_1_sub_card_2_content')}
                                        </SwTypography>
                                    </Box>
                                </SwCard>
                            </Grid>
                            <Grid item={true} md={4} xs={12}>
                                <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                    <Box p={2}>
                                        <SwTypography color={black} component={'h4'} variant={'h5'}>
                                            💵 {t('platform_page.precision_section_card_1_sub_card_3_title')}
                                        </SwTypography>
                                        <SwTypography color={grey3} variant={'body2'}>
                                            {t('platform_page.precision_section_card_1_sub_card_3_content')}
                                        </SwTypography>
                                    </Box>
                                </SwCard>
                            </Grid>
                            <Grid item={true} md={4} xs={12}>
                                <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                    <Box p={2}>
                                        <SwTypography color={black} component={'h4'} variant={'h5'}>
                                            🙅 {t('platform_page.precision_section_card_1_sub_card_4_title')}
                                        </SwTypography>
                                        <SwTypography color={grey3} variant={'body2'}>
                                            {t('platform_page.precision_section_card_1_sub_card_4_content')}
                                        </SwTypography>
                                    </Box>
                                </SwCard>
                            </Grid>
                            <Grid item={true} md={4} xs={12}>
                                <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                    <Box p={2}>
                                        <SwTypography color={black} component={'h4'} variant={'h5'}>
                                            🪨 {t('platform_page.precision_section_card_1_sub_card_5_title')}
                                        </SwTypography>
                                        <SwTypography color={grey3} variant={'body2'}>
                                            {t('platform_page.precision_section_card_1_sub_card_5_content')}
                                        </SwTypography>
                                    </Box>
                                </SwCard>
                            </Grid>
                            <Grid item={true} md={4} xs={12}>
                                <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                    <Box p={2}>
                                        <SwTypography color={black} component={'h4'} variant={'h5'}>
                                            🌱 {t('platform_page.precision_section_card_1_sub_card_6_title')}
                                        </SwTypography>
                                        <SwTypography color={grey3} variant={'body2'}>
                                            {t('platform_page.precision_section_card_1_sub_card_6_content')}
                                        </SwTypography>
                                    </Box>
                                </SwCard>
                            </Grid>
                        </Grid>
                    </Box>
                </SwCard>

                <SwCard background={white}>
                    <Box px={3} py={2}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12}>
                                <SwTypography component={'h3'} variant={'h4'}>
                                    {t('platform_page.precision_section_card_2_title')}
                                </SwTypography>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <SwTypography color={grey3} variant={'body1'}>
                                    {t('platform_page.precision_section_card_2_content')}
                                </SwTypography>
                            </Grid>
                            <Grid item={true} md={3} xs={12}>
                                <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                    <Box p={2}>
                                        <SwTypography color={black} component={'h4'} variant={'h5'}>
                                            🔔 {t('platform_page.precision_section_card_2_sub_card_1_title')}
                                        </SwTypography>
                                        <SwTypography color={grey3} variant={'body2'}>
                                            {t('platform_page.precision_section_card_2_sub_card_1_content')}
                                        </SwTypography>
                                    </Box>
                                </SwCard>
                            </Grid>
                            <Grid item={true} md={3} xs={12}>
                                <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                    <Box p={2}>
                                        <SwTypography color={black} component={'h4'} variant={'h5'}>
                                            📬 {t('platform_page.precision_section_card_2_sub_card_2_title')}
                                        </SwTypography>
                                        <SwTypography color={grey3} variant={'body2'}>
                                            {t('platform_page.precision_section_card_2_sub_card_2_content')}
                                        </SwTypography>
                                    </Box>
                                </SwCard>
                            </Grid>
                            <Grid item={true} md={3} xs={12}>
                                <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                    <Box p={2}>
                                        <SwTypography color={black} component={'h4'} variant={'h5'}>
                                            🗂 {t('platform_page.precision_section_card_2_sub_card_3_title')}
                                        </SwTypography>
                                        <SwTypography color={grey3} variant={'body2'}>
                                            {t('platform_page.precision_section_card_2_sub_card_3_content')}
                                        </SwTypography>
                                    </Box>
                                </SwCard>
                            </Grid>
                            <Grid item={true} md={3} xs={12}>
                                <SwCard background={grey7} borderColor={grey5} height={'100%'}>
                                    <Box p={2}>
                                        <SwTypography color={black} component={'h4'} variant={'h5'}>
                                            🙋 {t('platform_page.precision_section_card_2_sub_card_4_title')}
                                        </SwTypography>
                                        <SwTypography color={grey3} variant={'body2'}>
                                            {t('platform_page.precision_section_card_2_sub_card_4_content')}
                                        </SwTypography>
                                    </Box>
                                </SwCard>
                            </Grid>
                        </Grid>
                    </Box>
                </SwCard>
            </SwContainer>
        </div>
    );
});

export default SwSectionPrecision;
