import { usePlatformContainer } from '../PlatformProvider';
import { PlatformSection } from '../reducers/platformReducer';

export const useSubNavCardsActions = (tabs: PlatformSection[]) => {
    const [
        {
            climateTool: { activeArea }
        },
        dispatch
    ] = usePlatformContainer();

    const nextCard = () => {
        const next =
            !activeArea || activeArea.number === tabs[tabs.length - 1].number
                ? tabs[0]
                : tabs.find(({ number }) => number === activeArea.number + 1);

        if (next) {
            dispatch({
                type: 'changeActiveArea',
                payload: { activeArea: next, isScrollingToTarget: false }
            });
        }
    };

    const prevCard = () => {
        const prev =
            !activeArea || activeArea.number === tabs[0].number
                ? tabs[tabs.length - 1]
                : tabs.find(({ number }) => number === activeArea.number - 1);

        if (prev) {
            dispatch({
                type: 'changeActiveArea',
                payload: { activeArea: prev, isScrollingToTarget: false }
            });
        }
    };

    const handleCardActivation = (section: PlatformSection) => {
        dispatch({
            type: 'changeActiveArea',
            payload: { activeArea: section, isScrollingToTarget: false }
        });
    };

    return {
        nextCard,
        handleCardActivation,
        prevCard
    };
};
