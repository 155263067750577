import { CaretRight, CaretLeft } from '@phosphor-icons/react';
import React from 'react';
import { SwButtonKind, SwButton, SwButtonProps } from '../../lib/components/bedrock/button/SwButton';

interface SwSubNavArrowProps extends Omit<SwButtonProps, 'kind'> {
    direction: 'left' | 'right';
}

const SwSubNavArrow: React.FC<SwSubNavArrowProps> = (props) => {
    const { direction, onClick } = props;

    return (
        <SwButton ariaLabel={'zoom in'} kind={SwButtonKind.Secondary} size={'small'} onClick={onClick}>
            {direction === 'right' ? <CaretRight size={18} /> : null}
            {direction === 'left' ? <CaretLeft size={18} /> : null}
        </SwButton>
    );
};

export default SwSubNavArrow;
